import React, { FC, forwardRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Box, OutlinedTextFieldProps } from '@material-ui/core';

export interface U1AutocompleteTextFieldProps extends Partial<OutlinedTextFieldProps> {
  label: string;
  listItems?: Array<{ value: string; label: string }>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: 38,
      '& .MuiTextField-root': {
        maxHeight: 38,
        '& label': {
          top: -7,
          color: theme.palette.secondary.main,
        },
        '& .MuiInputLabel-shrink': {
          display: 'none',
        },
        '& .MuiOutlinedInput-root': {
          maxHeight: 38,
          borderRadius: 0,
          backgroundColor: '#FAFAFA',
          '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
            maxHeight: 38,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
            '&:hover': {
              borderColor: 'transparent',
            },
          },
          '& .MuiSelect-outlined': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
            borderRadius: 0,
            '&:focus': {
              backgroundColor: theme.palette.common.white,
              color: theme.palette.common.black,
              border: `1px solid ${theme.palette.primary.contrastText}`,
              borderRadius: 0,
            },
          },
        },
        '&:hover': {
          color: 'transparent',
        },
      },
    },
    input: {
      padding: 11,
      paddingLeft: theme.spacing(2),
      // Remove arrows on input number (Chrome, Safari, Edge, Opera)
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      // Remove arrows on input number (Firefox)
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        // margin: theme.spacing(2),
      },
    },
    menu: {
      marginTop: theme.spacing(8),
    },
    paper: {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.primary.contrastText}`,
      borderRadius: 0,
      padding: theme.spacing(2),
      backgroundColor: '#FAFAFA',
    },
    list: { padding: 0 },
    helperText: {
      color: theme.palette.error.main,
      fontSize: 13,
      textAlign: 'right',
      marginRight: 0,
    },
  }),
);

const U1AutocompleteTextField: FC<U1AutocompleteTextFieldProps> = forwardRef(({ label, listItems, ...props }, ref) => {
  const classes = useStyles();
  const { inputProps, SelectProps, ...rest } = props;

  return (
    <Box className={classes.root}>
      <TextField
        id="standard-select"
        select
        label={label}
        variant="outlined"
        fullWidth
        ref={ref}
        {...rest}
        inputProps={{ ...inputProps, className: classes.input }}
        SelectProps={{
          ...SelectProps,
          MenuProps: {
            className: classes.menu,
            classes: { paper: classes.paper, list: classes.list },
          },
        }}
        FormHelperTextProps={{ className: classes.helperText }}
      >
        {listItems.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
});
export default U1AutocompleteTextField;
