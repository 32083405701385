import { FC } from 'react';
import { Container, Divider, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { SliderArrowButton } from './index';

interface ArrowsProps extends IDefaultArrowsProps {
  onClickIndicator?: (slide: number) => void;
  prev?: () => void;
  next?: () => void;
}

export interface IDefaultArrowsProps {
  currentSlide: number;
  totalSlides: Array<number>;
  onClickIndicator?: (slide: number) => void;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    arrows: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(-14),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(-15),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(-20),
      },
    },
  });
});

const SliderArrows: FC<ArrowsProps> = ({ totalSlides, currentSlide, onClickIndicator, prev, next }) => {
  const classes = useStyles({ currentSlide, totalSlides, onClickIndicator });

  const nextFunc = () => {
    if (currentSlide >= totalSlides.length - 1) {
      onClickIndicator(0);
    } else {
      onClickIndicator(currentSlide + 1);
    }
  };

  const prevFunc = () => {
    if (currentSlide < 1) {
      onClickIndicator(totalSlides.length - 1);
    } else {
      onClickIndicator(currentSlide - 1);
    }
  };

  return (
    <Container className={clsx(classes.arrows)} fixed>
      <SliderArrowButton color={'primary'} onClick={prev ? prev : prevFunc} aria-label="Prev" isPrev>
        Prev
      </SliderArrowButton>
      <Divider orientation="vertical" />
      <SliderArrowButton onClick={next ? next : nextFunc} aria-label="Next">
        Next
      </SliderArrowButton>
    </Container>
  );
};
export default SliderArrows;
