import React, { FC } from 'react';
import { Button, ButtonProps, makeStyles, Theme, Typography } from '@material-ui/core';

interface InfoButtonProps extends ButtonProps {
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.primary.dark,
    fontWeight: 400,
    minWidth: 171,
    minHeight: 50,
    color: 'transparent',
    boxShadow: 'none',
    borderRadius: theme.spacing(4),
    padding: theme.spacing(2, 7),
    '&:hover': {
      color: 'transparent',
      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
      backgroundColor: theme.palette.primary.dark,
    },
  },
  text: {
    color: theme.palette.common.white,
  },
}));

const InfoButton: FC<InfoButtonProps> = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button variant={'contained'} className={classes.button} onClick={onClick}>
      <Typography variant="body1" className={classes.text}>
        Learn more
      </Typography>
    </Button>
  );
};

export default InfoButton;
