import React, { FC } from 'react';
import { FormControlLabel, Switch, SwitchProps } from '@material-ui/core';

interface U1SwitchProps extends SwitchProps {
  text: string;
}

const U1Switch: FC<U1SwitchProps> = (props) => {
  const { text, ...rest } = props;

  return <FormControlLabel control={<Switch color="primary" {...rest} />} label={text} />;
};

export default U1Switch;
