const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const displayPublishAt = (value: string) => {
  const date = new Date(value);
  return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
};

export const maxDate = (strings: string[]) => {
  const times = strings.map((s) => (s ? new Date(s).getTime() : 0));
  return strings[times.indexOf(Math.max(...times))];
};
