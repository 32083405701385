import React, { FC } from 'react';
import { Box, Button, ButtonProps, makeStyles, Theme, Typography } from '@material-ui/core';
import { FbIcon, InstagramIcon, LnIcon } from 'svgs';


const backgrounds = [
  ['#34599D', '#34599D'],
  ['#0077B5', '#0077B5'],
  [
    'linear-gradient(90deg, rgba(250,232,0,1) 0%, rgba(252,183,32,1) 14%, rgba(255,121,80,1) 29%, rgba(255,28,116,1) 52%, rgba(108,28,209,1) 100%)',
    'linear-gradient(90deg, rgba(250,232,0,1) 0%, rgba(252,183,32,1) 14%, rgba(255,121,80,1) 29%, rgba(255,28,116,1) 52%, rgba(108,28,209,1) 100%)'
  ],
]

interface SocialMediaButtonProps extends ButtonProps {
  text: string;
  onClick?: () => void;
  isFb?: boolean;
  isLn?: boolean;
  isIn?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    padding: `12px 32px !important`,
    width: '100%',
    background: ({ isFb, isLn, isIn }: Partial<SocialMediaButtonProps>) => backgrounds[[isFb, isLn, isIn].indexOf(true)][0],
    '&:hover': {
      background: ({ isFb, isLn, isIn }: Partial<SocialMediaButtonProps>) => backgrounds[[isFb, isLn, isIn].indexOf(true)][1],
    },
  },
  text: {
    fontWeight: 500,
  },
}));

const SocialMediaButton: FC<SocialMediaButtonProps> = ({ text, onClick, isFb = false, isLn = false, isIn = false }) => {
  const classes = useStyles({ isFb, isLn, isIn });
  return (
    <Button variant="contained" className={classes.button} onClick={onClick}>
      {isFb && <FbIcon />}
      {isLn && <LnIcon />}
      {isIn && <InstagramIcon />}
      <Box mr={1} />
      <Typography variant="body1" className={classes.text}>
        {text}
      </Typography>
    </Button>
  );
};

export default SocialMediaButton;
