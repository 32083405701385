import { Box, InputAdornment, makeStyles, OutlinedTextFieldProps, TextField, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { ChangeEventHandler, FC, forwardRef, ReactNode } from 'react';
import { AcceptIcon } from 'svgs/careers';

export interface U1TextFieldProps extends Partial<OutlinedTextFieldProps> {
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  label?: string;
  value?: string;
  startIcon?: ReactNode;
  hasIcon?: boolean;
  maxLength?: number;
  isUploadFileInput?: boolean;
  onClick?: () => void;
  hasFile?: boolean;
}

type StyleProps = {
  hasIcon?: boolean;
  isUploadFileInput?: boolean;
  isExperienceField?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    '& div': {
      maxHeight: 264,
      [theme.breakpoints.up('sm')]: {
        minHeight: 132,
      },
      '& div': {
        '& textarea': {
          minHeight: 264,
          paddingTop: theme.spacing(2),
          [theme.breakpoints.up('sm')]: {
            minHeight: 132,
          },
          '&:focus': {
            paddingTop: theme.spacing(2),
            maxHeight: 264,
            height: '266px !important',
            [theme.breakpoints.up('sm')]: {
              height: '132px !important',
            },
          },
        },
      },
    },
  },
  root: {
    maxHeight: 38,
    '& p': {
      color: theme.palette.error.main,
      fontSize: 13,
      marginRight: 0,
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      position: 'relative',

      '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
      },
      '& svg': {
        left: theme.spacing(2),
        position: 'absolute',
      },
    },
    '& .MuiInputLabel-shrink': {
      display: 'none',
    },
    '& fieldset': {
      zIndex: -1,
      borderWidth: '0 !important',
      '& legend': {
        maxWidth: '0 !important',
      },
    },
  },
  input: {
    color: theme.palette.secondary.main,
    minHeight: 38,
    maxHeight: 38,
    backgroundColor: '#FAFAFA',
    border: `1px solid ${theme.palette.primary.contrastText}`,
    padding: ({ hasIcon }: StyleProps) => (hasIcon ? theme.spacing(0, 7) : theme.spacing(0, 2)),
    '&:focus': {
      border: ({ isUploadFileInput }: StyleProps) =>
        isUploadFileInput ? `1px solid ${theme.palette.primary.contrastText}` : `1px solid ${theme.palette.primary.main}`,
      paddingLeft: ({ hasIcon }: StyleProps) => (hasIcon ? theme.spacing(7) : theme.spacing(2)),
    },
    '&:hover': {
      cursor: ({ isUploadFileInput }: StyleProps) => isUploadFileInput && 'pointer',
    },
    // Remove arrows on input number (Chrome, Safari, Edge, Opera)
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    // Remove arrows on input number (Firefox)
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      borderRadius: 0,
    },
  },
  placeholder: {
    marginLeft: ({ hasIcon, isUploadFileInput }: StyleProps) =>
      (isUploadFileInput && theme.spacing(22)) || (hasIcon ? theme.spacing(5) : 0),
    marginTop: theme.spacing(-1),
    color: theme.palette.secondary.main,
    width: '100%',
    '&:focus': {
      color: 'transparent !important',
      borderTop: '1px solid transparent !important',
    },
  },
  helperText: {
    fontSize: 15,
    textAlign: 'right',
    margin: theme.spacing(0, 2),
  },
  textField: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: theme.spacing(1.5, 1.8),
    maxWidth: 139,
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: 0,
  },
  textField1: {
    marginLeft: 145,
    color: theme.palette.secondary.main,
    top: -29,
    cursor: 'pointer',
    '& svg': {
      marginTop: 4,
      marginLeft: 10,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 158,
    },
  },
  counter: {
    position: 'absolute',
    bottom: 0,
    right: theme.spacing(1),
    color: theme.palette.secondary.light,
  },
}));

const U1TextField: FC<U1TextFieldProps> = forwardRef(
  (
    {
      value,
      label,
      error,
      maxLength,
      disabled,
      startIcon,
      className,
      type,
      hasIcon = false,
      isUploadFileInput = false,
      hasFile = false,
      onClick,
      onChange,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles({ hasIcon, isUploadFileInput });

    return (
      <Box className={maxLength && classes.box} position="relative">
        <TextField
          {...props}
          fullWidth
          ref={ref}
          type={type}
          label={!hasFile && label}
          disabled={disabled}
          value={value}
          error={error}
          onClick={onClick}
          onChange={onChange}
          autoComplete="off"
          className={clsx(classes.root, className)}
          variant="outlined"
          FormHelperTextProps={{ className: classes.helperText }}
          inputProps={{ ...props.inputProps, className: classes.input, maxLength }}
          InputProps={{
            ...props.InputProps,
            endAdornment: startIcon && <InputAdornment position="start">{startIcon}</InputAdornment>,
          }}
          InputLabelProps={{
            ...props.InputLabelProps,
            className: classes.placeholder,
          }}
        />
        {isUploadFileInput && (
          <>
            <Typography variant="body1" className={classes.textField}>
              Upload your CV
            </Typography>
            {hasFile && (
              <Box
                flexDirection="row"
                justifyContent="flex-start"
                display="flex"
                position="relative"
                className={classes.textField1}
                onClick={onClick}
              >
                <Typography variant="body1">Upload your CV</Typography>
                <AcceptIcon />
              </Box>
            )}
          </>
        )}
        {maxLength && (
          <Typography variant="subtitle2" className={classes.counter}>
            {value.length} / {maxLength}
          </Typography>
        )}
      </Box>
    );
  },
);

export default U1TextField;
