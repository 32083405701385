import React, { FC } from 'react';
import { Button, ButtonProps, makeStyles, Theme, Typography } from '@material-ui/core';
import { ArrowIcon } from 'svgs';
import Link from 'next/link';

interface InfoButtonProps extends ButtonProps {
  onClick?: () => void;
  text: string;
  link: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '100%',
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
    willChange: 'backgroundColor, color',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& span': {
        '& svg': {
          '& path': {
            fill: theme.palette.common.white,
          },
        },
      },
    },
  },
  text: {
    fontWeight: 500,
    textAlign: 'left',
  },
}));

const SubcontractingButton: FC<InfoButtonProps> = ({ onClick, text, link }) => {
  const classes = useStyles();
  return (
    <Link href={link}>
      <Button variant={'contained'} className={classes.button} onClick={onClick}>
        <Typography variant="body1" className={classes.text}>
          {text}
        </Typography>
        <ArrowIcon position="left" />
      </Button>
    </Link>
  );
};

export default SubcontractingButton;
