import React, { FC } from 'react';
import { Box, Button, ButtonProps, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { CarouselArrowIconPos } from 'svgs';

interface CarouselButtonProps extends ButtonProps {
  whiteButton?: boolean;
  isPrev?: boolean;
  text: string;
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontWeight: 500,
    minWidth: 120,
    minHeight: 58,
    border: `1px solid ${theme.palette.primary.contrastText}`,
    '& span': {
      '& div': {
        '& svg': {
          '& path': {
            fill: theme.palette.primary.main,
          },
        },
      },
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
      color: 'transparent',
      '& span': {
        '& div': {
          '& span': {
            color: theme.palette.common.white,
          },
          '& svg': {
            '& path': {
              fill: theme.palette.common.white,
            },
          },
        },
      },
    },
  },
  whiteButton: {
    border: `1px solid ${theme.palette.common.white}`,
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    '& span': {
      '& p': {
        '& div': {
          '& svg': {
            '& path': {
              fill: theme.palette.common.white,
            },
          },
        },
      },
    },
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  text: {
    fontSize: 17,
    fontWeight: 500,
  },
  iconPrev: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(-0.4),
  },
  iconNext: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(-0.4),
  },
}));

const CarouselButton: FC<CarouselButtonProps> = ({ whiteButton = false, isPrev = false, text, onClick }) => {
  const classes = useStyles();
  return (
    <Button variant={'contained'} className={clsx(classes.button, whiteButton && classes.whiteButton)} onClick={onClick}>
      {isPrev ? (
        <Box>
          <CarouselArrowIconPos className={classes.iconPrev} position="right" />
          <span className={classes.text}>{text}</span>
        </Box>
      ) : (
        <Box>
          <span className={classes.text}>{text}</span>
          <CarouselArrowIconPos className={classes.iconNext} position="left" />
        </Box>
      )}
    </Button>
  );
};

export default CarouselButton;
