import React, { FC, FormEvent, ReactNode } from 'react';
import { Button, ButtonProps, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';

interface SubmitButtonProps extends ButtonProps {
  children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    background: theme.palette.gradients.lightDarkOrange,
    color: theme.palette.common.white,
    minWidth: 135,
    minHeight: 50,
    '&:hover': {
      background: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    '&:disabled': {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  text: {
    fontSize: 17,
  },
  successButton: {
    backgroundColor: 'green',
    fontWeight: 400,
    width: '100%',
    transition: 'all 200ms',
    '&:hover': {
      transform: 'translate(0)',
      backgroundColor: 'green',
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
  },
  errorButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 400,
    width: '100%',
    transition: 'all 200ms',
    '&:hover': {
      transform: 'translate(0)',
      backgroundColor: theme.palette.primary.main,
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
  },
  disabledButton: {
    backgroundColor: theme.palette.common.white,
    border: '2px solid #9299A6',
    fontWeight: 400,
    width: '100%',
    color: '#9299A6',
    minWidth: 135,
    minHeight: 50,
    transition: 'all 200ms',
    '&:hover': {
      transform: 'translate(0)',
      backgroundColor: theme.palette.common.white,
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
    '& .MuiTouchRipple-root': {
      opacity: 0,
    },
  },
}));

interface SubmitButtonProps extends ButtonProps {
  error?: boolean;
  success?: boolean;
  check?: boolean;
}

const SubmitButton: FC<SubmitButtonProps> = (props) => {
  const { children, error = false, success = false, check = false, ...rest } = props;
  const classes = useStyles();
  const preventClick = (e: FormEvent) => {
    if (success) e.preventDefault();
  };
  return (
    <Button
      variant={'contained'}
      type="submit"
      className={
        !check ? classes.disabledButton : clsx(classes.button, error && classes.errorButton, success && classes.successButton)
      }
      onClick={preventClick}
      {...rest}
    >
      <Typography className={classes.text}>{children}</Typography>
    </Button>
  );
};

export default SubmitButton;
