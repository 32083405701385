import { SvgIconProps } from '@material-ui/core';
import { FC, forwardRef } from 'react';
import { ReactComponent as LocationIconSvg } from './location-icon.svg';
import { ReactComponent as ListIconSvg } from './list-icon.svg';
import { ReactComponent as TimeIconSvg } from './time-icon.svg';
import { ReactComponent as JobTypeIconSvg } from './job-type-icon.svg';
import { ReactComponent as SearchIconSvg } from './search-icon.svg';
import { ReactComponent as FilterIconSvg } from './filter-icon.svg';
import { ReactComponent as FileUploaderIconSvg } from './file-uploader.svg';
import { ReactComponent as DropzoneIconSvg } from './dropzone-icon.svg';
import { ReactComponent as AcceptIconSvg } from './accept-icon.svg';

export const LocationIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LocationIconSvg ref={ref} {...props} />);
export const ListIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ListIconSvg ref={ref} {...props} />);
export const TimeIcon: FC<SvgIconProps> = forwardRef((props, ref) => <TimeIconSvg ref={ref} {...props} />);
export const JobTypeIcon: FC<SvgIconProps> = forwardRef((props, ref) => <JobTypeIconSvg ref={ref} {...props} />);
export const SearchIcon: FC<SvgIconProps> = forwardRef((props, ref) => <SearchIconSvg ref={ref} {...props} />);
export const FilterIcon: FC<SvgIconProps> = forwardRef((props, ref) => <FilterIconSvg ref={ref} {...props} />);
export const FileUploaderIcon: FC<SvgIconProps> = forwardRef((props, ref) => <FileUploaderIconSvg ref={ref} {...props} />);
export const DropzoneIcon: FC<SvgIconProps> = forwardRef((props, ref) => <DropzoneIconSvg ref={ref} {...props} />);
export const AcceptIcon: FC<SvgIconProps> = forwardRef((props, ref) => <AcceptIconSvg ref={ref} {...props} />);
