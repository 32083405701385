import React, { FC } from 'react';
import { Button, ButtonProps, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';

interface PrimaryButtonProps extends ButtonProps {
  whiteButton?: boolean;
  text: string;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
    fontWeight: 400,
    minWidth: 173,
    minHeight: 50,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  text: {
    fontSize: 17,
  },
  whiteButton: {
    border: `1px solid ${theme.palette.common.white}`,
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

const PrimaryButton: FC<PrimaryButtonProps> = ({ whiteButton = false, text, onClick }) => {
  const classes = useStyles();
  return (
    <Button variant={'contained'} className={clsx(classes.button, whiteButton && classes.whiteButton)} onClick={onClick}>
      <Typography className={classes.text}>{text}</Typography>
    </Button>
  );
};

export default PrimaryButton;
