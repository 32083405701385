import React, { FC } from 'react';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import ReadButton from './ReadButton';
import { CalendarIcon } from 'svgs';
import { displayPublishAt } from 'utils/date';
import Image from 'blog/components/Image';

export interface CardProps {
  subtitle: string;
  title: string;
  text: string;
  image: string;
  width?: number;
  height?: number;
  link: string;
  isNewsBlog?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: theme.palette.common.white,
    minHeight: '100%',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
  },

  dateBox: {
    display: 'flex',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.secondary.light,
    margin: theme.spacing(4, 0),
  },
  contentBox: {
    padding: theme.spacing(4, 5, 2, 5),
    height: '100%',
  },
  subtitle: {
    marginLeft: theme.spacing(2),
  },
  location: {
    margin: theme.spacing(3, 0, 5),
  },
  button: {
    padding: theme.spacing(0, 5, 6, 5),
  },
  imageContainer: {
    objectFit: 'contain',
    width: '100%',
    position: 'relative',
    height: 'unset',
  },
}));

const ProjectCard: FC<CardProps> = ({ title, text, link, image, width, height, subtitle, isNewsBlog }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" justify="space-between" className={classes.card} data-aos="fade-up">
      <Grid item>
        <Box position="relative" className={classes.imageContainer}>
          <Image src={image} alt={image} width={width} height={height} />
        </Box>
        <Box className={classes.contentBox}>
          <Box className={classes.dateBox}>
            <CalendarIcon />
            {isNewsBlog ? (
              <Typography className={classes.subtitle}>{displayPublishAt(subtitle)}</Typography>
            ) : (
              <Typography className={classes.subtitle}>{subtitle}</Typography>
            )}
          </Box>
          <Box className={classes.divider} />
          <Typography variant="h5">{title}</Typography>
          <Typography className={classes.location}>{text}</Typography>
        </Box>
      </Grid>
      <Grid className={classes.button} item>
        <ReadButton href={link} text="Read More" />
      </Grid>
    </Grid>
  );
};

export default ProjectCard;
