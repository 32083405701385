import React, { FC } from 'react';
import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { CarouselArrowIconPos } from 'svgs';

interface SliderArrowButtonProps extends ButtonProps {
  whiteButton?: boolean;
  isPrev?: boolean;
  onClick: (selectedIndex) => void;
  orangeArrow?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.common.white,
    fontWeight: 400,
    minWidth: 50,
    minHeight: 50,
    border: `1px solid ${theme.palette.secondary.light}`,
    color: 'transparent',
    boxShadow: 'none',
    '& span': {
      '& svg': {
        '& path': {
          fill: ({ orangeArrow }: Partial<SliderArrowButtonProps>) =>
            orangeArrow ? theme.palette.primary.main : theme.palette.primary.dark,
        },
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      color: 'transparent',
      boxShadow: 'none',
      '& span': {
        '& svg': {
          '& path': {
            fill: theme.palette.common.white,
          },
        },
      },
    },
  },
  whiteButton: {
    border: `1px solid ${theme.palette.common.white}`,
    backgroundColor: 'transparent',
    '& span': {
      '& svg': {
        '& path': {
          fill: theme.palette.common.white,
        },
      },
    },
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

const SliderArrowButton: FC<SliderArrowButtonProps> = ({ whiteButton = false, isPrev = false, onClick, orangeArrow = false }) => {
  const classes = useStyles({ orangeArrow });
  return (
    <Button variant={'contained'} className={clsx(classes.button, whiteButton && classes.whiteButton)} onClick={onClick}>
      {isPrev ? <CarouselArrowIconPos position="right" /> : <CarouselArrowIconPos position="left" />}
    </Button>
  );
};

export default SliderArrowButton;
