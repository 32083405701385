import React, { FC, ReactNode } from 'react';
import { Button, ButtonProps, makeStyles, Theme, Typography } from '@material-ui/core';

export interface SocialButtonProps extends ButtonProps {
  icon?: ReactNode;
  title: string;
  href: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: '#FAFAFA',
    fontWeight: 400,
    border: '1px solid #EBEBEB',
    minHeight: 46,
    color: 'transparent',
    boxShadow: 'none',
    borderRadius: theme.spacing(4),
    padding: theme.spacing(2, 3),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:hover': {
      color: 'transparent',
      backgroundColor: '#FAFAFA',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  text: {
    fontWeight: 500,
    paddingLeft: theme.spacing(1),
  },
}));

const SocialButton: FC<SocialButtonProps> = ({ href, title, icon }) => {
  const classes = useStyles();
  return (
    <Button variant={'contained'} className={classes.button} href={href}>
      {icon}
      <Typography variant="body1" color="textSecondary" className={classes.text}>
        {title}
      </Typography>
    </Button>
  );
};

export default SocialButton;
