import React, { FC } from 'react';
import { Box, Button, ButtonProps, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ArrowRightIcon } from 'svgs';

interface ReadButtonProps extends ButtonProps {
  whiteButton?: boolean;
  orangeButton?: boolean;
  text: string;
  onClick?: () => void;
  href?: string;
  withoutIcon?: boolean;
  active?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    fontWeight: 400,
    minWidth: 158,
    minHeight: 58,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  whiteButton: {
    border: `1px solid ${theme.palette.common.white}`,
    backgroundColor: 'transparent',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  orangeButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      '& span': {
        '& p': {
          color: theme.palette.common.white,
          '& svg': {
            '& path': {
              fill: theme.palette.common.white,
            },
          },
        },
      },
    },
  },
  text: {
    fontWeight: 500,
    color: theme.palette.common.white,
  },
  orangeText: {
    color: theme.palette.primary.main,
  },
  icon: {
    marginLeft: theme.spacing(2),
  },
  orangeIcon: {
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
}));

const ReadButton: FC<ReadButtonProps> = (props) => {
  const { href } = props;

  return href ? (
    <a href={href}>
      <InnerButton {...props} />
    </a>
  ) : (
    <InnerButton {...props} />
  );
};

const InnerButton: FC<ReadButtonProps> = ({
  whiteButton = false,
  orangeButton = false,
  text,
  href,
  onClick,
  withoutIcon = false,
  active = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button
      variant={'contained'}
      className={clsx(
        classes.button,
        whiteButton && classes.whiteButton,
        orangeButton && classes.orangeButton,
        active && classes.active,
      )}
      onClick={onClick}
      {...props}
    >
      <Typography variant="body1" className={clsx(classes.text, orangeButton && classes.orangeText)}>
        <Box display="flex" alignItems="center">
          {text}
          {!withoutIcon && <ArrowRightIcon className={clsx(classes.icon, orangeButton && classes.orangeIcon)} />}
        </Box>
      </Typography>
    </Button>
  );
};

export default ReadButton;
