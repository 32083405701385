import React, { FC, ReactNode } from 'react';
import { Box, Card, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import Link from 'next/link';
import { BigArrowRightIcon } from 'svgs';

interface CardProps {
  title: string;
  text: string;
  icon: ReactNode;
  image1: string;
  image2: string;
  href: string;
}

type StyleProps = {
  image1: string;
  image2: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  card: {
    padding: theme.spacing(18.5, 8.3, 8.3),
    backgroundImage: ({ image1 }: Partial<CardProps>) => `url(${image1})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    cursor: 'pointer',
    boxShadow: 'none',
    borderRadius: 0,
    height: 400,
    position: 'relative',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundImage: ({ image2 }: Partial<StyleProps>) => `url(${image2})`,
      padding: theme.spacing(6, 8.3, 19.8),
      '& .MuiTypography-root': {
        display: 'block',
      },
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.light,
    maxWidth: 94,
    minHeight: 3,
    borderRadius: theme.spacing(2),
  },
  textColor: {
    color: theme.palette.common.white,
    maxWidth: 453,
  },
  orangeButton: {
    background: theme.palette.gradients.darkOrange,
    color: theme.palette.common.white,
    maxHeight: 89,
    padding: theme.spacing(5, 8.3),
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    display: 'none',
    '& svg': {
      marginBottom: theme.spacing(-0.5),
      marginLeft: theme.spacing(3),
    },
  },
}));

const U1Card: FC<CardProps> = ({ title, text, href, icon, image1, image2 }) => {
  const classes = useStyles({ image1, image2 });

  return (
    <Link href={href}>
      <a className={classes.root}>
        <Card className={classes.card}>
          <Box mb={4}>{icon}</Box>
          <Typography className={classes.textColor}>
            <Box fontSize={36} fontWeight={600} mb={3.5}>
              {title}
            </Box>
          </Typography>
          <Divider className={classes.divider} />
          <Box mt={5} />
          <Typography className={classes.textColor} variant="h5">
            {text}
          </Typography>
          <Typography variant="h4" className={classes.orangeButton}>
            <Box fontWeight={600}>
              Discover <BigArrowRightIcon />
            </Box>
          </Typography>
        </Card>
      </a>
    </Link>
  );
};

export default U1Card;
